<template>
    <div class="video-gallery bg-white py-10 px-6">
      <div class="container mx-auto max-w-screen-xl grid grid-cols-1 gap-6 md:grid-cols-3 items-start">
        <!-- Left Videos -->
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div
            v-for="(video, index) in videos.slice(0, 2)" 
            :key="`left-video-${index}`"
            class="relative group overflow-hidden rounded-lg shadow-md"
          >
            <!-- Video Thumbnail -->
            <div class="relative">
              <img :src="video.thumbnail" :alt="video.title" class="w-full h-full object-cover" />
              <!-- Play Button -->
              <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <i class="fas fa-play-circle text-white text-4xl"></i>
              </div>
            </div>
  
            <!-- Video Title -->
            <div class="text-center mt-2 px-2">
              <h3 class="text-sm font-semibold">{{ video.title }}</h3>
            </div>
          </div>
        </div>
  
        <!-- Center Section -->
        <div class="flex flex-col items-center justify-center text-center my-4 md:my-0">
          <i class="fas fa-play-circle text-4xl text-gray-500 mb-4"></i>
          <h3 class="text-xl font-semibold mb-2">Video</h3>
          <p class="text-sm text-gray-700">DEPOK BRANDED STOCKLOTS</p>
        </div>
  
        <!-- Right Videos -->
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div
            v-for="(video, index) in videos.slice(2, 4)" 
            :key="`right-video-${index}`"
            class="relative group overflow-hidden rounded-lg shadow-md"
          >
            <!-- Video Thumbnail -->
            <div class="relative">
              <img :src="video.thumbnail" :alt="video.title" class="w-full h-full object-cover" />
              <!-- Play Button -->
              <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <i class="fas fa-play-circle text-white text-4xl"></i>
              </div>
            </div>
  
            <!-- Video Title -->
            <div class="text-center mt-2 px-2">
              <h3 class="text-sm font-semibold">{{ video.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoGallery',
    data() {
      return {
        videos: [
          {
            thumbnail: 'https://via.placeholder.com/320x180.png?text=Video+1',
            title: 'Mix and Match CLOTHES CHILDREN Branded the rest of the Export from the Factory',
          },
          {
            thumbnail: 'https://via.placeholder.com/320x180.png?text=Video+2',
            title: 'Orderan Testimonials of Branded Shirt Business Packages Remaining Export From ACEH',
          },
          {
            thumbnail: 'https://via.placeholder.com/320x180.png?text=Video+3',
            title: 'Opening Depok Branded Stocklots Post Vacation Directly on BORONG PACKAGE 20 MILLION',
          },
          {
            thumbnail: 'https://via.placeholder.com/320x180.png?text=Video+4',
            title: 'Look for TRUST SUPPLIER CONTACTS For Branded Shirt Business Remaining Export',
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .video-gallery .container {
    max-width: 1900px;
  }
  
  .video-gallery img {
    transition: transform 0.3s ease;
  }
  
  .video-gallery img:hover {
    transform: scale(1.05);
  }
  
  .video-gallery .group:hover .bg-opacity-50 {
    opacity: 1;
  }
  </style>
  
  <!-- Include Font Awesome for Icons -->
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  </style>
  