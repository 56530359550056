<template>
    <footer class="bg-gray-50 py-10 px-6 border-t border-gray-200">
      <div class="container mx-auto max-w-screen-xl">
        <!-- Upper Section: Testimonials, Facebook, Twitter -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <!-- Testimonials -->
          <div>
            <h4 class="text-lg font-semibold text-gray-700 border-b-2 border-orange-500 pb-2 mb-4">Testimonials</h4>
            <p class="text-gray-600 mb-4">
              "Alhamdulillah, he has seen his clothes are good-good, and cheap. I thought at first it was a lie, my tpi ..."
            </p>
            <p class="text-gray-500">lin</p>
          </div>
  
          <!-- Facebook Embed -->
          <div>
            <h4 class="text-lg font-semibold text-gray-700 border-b-2 border-orange-500 pb-2 mb-4">Facebook</h4>
            <div class="flex justify-center">
              <!-- Facebook embed -->
              <!-- <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDepokBrandedStocklots&tabs&width=340&height=500" width="340" height="160" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->
            </div>
          </div>
  
          <!-- Twitter Embed -->
          <div>
            <h4 class="text-lg font-semibold text-gray-700 border-b-2 border-orange-500 pb-2 mb-4">Twitter</h4>
            <div class="flex justify-center">
              <!-- Twitter embed -->
              <blockquote class="twitter-tweet">
                <!-- <p lang="en" dir="ltr">Posts from <a href="https://twitter.com/stargadgetacc">@stargadgetacc</a></p> -->
              </blockquote>
            </div>
          </div>
        </div>
  
        <!-- Lower Section: Subscription, Shipping, Payment, Contact -->
        <div class="grid grid-cols-1 md:grid-cols-4 gap-6">
          <!-- Email Subscription -->
          <div class="space-y-4">
            <h4 class="text-lg font-semibold text-gray-700">Email Subscription</h4>
            <form class="flex">
              <input
                type="email"
                placeholder="Enter Your Email"
                class="flex-1 px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none"
              />
              <button type="submit" class="bg-orange-500 text-white px-4 py-2 rounded-r-md">
                <i class="fas fa-envelope"></i>
              </button>
            </form>
          </div>
  
          <!-- Shipping Info -->
          <div class="space-y-4">
            <h4 class="text-lg font-semibold text-gray-700">Shipping Info</h4>
            <p class="text-gray-600">Shipping Services That Have Been Collaborating With Us</p>
            <div class="flex space-x-2">
              <img src="@/assets/shipping/1.jpg" alt="JNE" class="w-14 h-auto" />
              <img src="@/assets/shipping/2.jpg" alt="POS Indonesia" class="w-14 h-auto" />
              <img src="@/assets/shipping/3.jpg" alt="TIKI" class="w-14 h-auto" />
              <img src="@/assets/shipping/4.jpg" alt="J&T Express" class="w-14 h-auto" />
            </div>
          </div>
  
          <!-- Payment Info -->
          <div class="space-y-4">
            <h4 class="text-lg font-semibold text-gray-700">Payment Info</h4>
            <p class="text-gray-600">
              Payment Only Through: BCA, Mandiri
            </p>
            <div class="flex space-x-2">
              <img src="@/assets/payment/1.jpg" alt="BCA" class="w-14 h-auto" />
              <img src="@/assets/payment/2.jpg" alt="VISA" class="w-14 h-auto" />
              <img src="@/assets/payment/3.jpg" alt="MasterCard" class="w-14 h-auto" />
              <img src="@/assets/payment/4.jpg" alt="Mandiri" class="w-14 h-auto" />
            </div>
          </div>
  
          <!-- Contact Info -->
          <!-- <div class="space-y-4">
            <h4 class="text-lg font-semibold text-gray-700">Depok Branded Stocklots</h4>
            <p class="text-gray-600">
              Perum Bukit Rivaria Sector 3, Block A 3 No.12 A, Sawangan Baru, City of Depok, West Java - Indonesia
            </p>
            <p class="text-gray-600">Phone: 085313832578 / 085216964775</p>
            <p class="text-gray-600">WhatsApp: 081385864519</p>
            <p class="text-gray-600">Email: depokbrandedstocklots@gmail.com</p>
          </div> -->
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
  };
  </script>
  
  <style scoped>
  footer {
    background-color: #f8f9fa;
  }
  
  footer h4 {
    color: #333;
  }
  
  footer p,
  footer form input {
    color: #666;
  }
  
  footer form input:focus {
    border-color: #ff7c3e;
  }
  
  footer form button {
    background-color: #ff7c3e;
  }
  
  footer form button:hover {
    background-color: #e06b33;
  }
  
  footer .space-x-2 img {
    max-width: 60px;
  }
  </style>
  
  <!-- Include Font Awesome for Icons -->
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  </style>
  