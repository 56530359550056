<template>
    <!-- Top Navbar with Language Selector and Login/Signup -->
    <nav class="bg-gray-900 text-white">
      <div class="container mx-auto flex justify-between items-center py-2 px-4">
        <!-- Language Selector -->
        <div class="flex items-center space-x-4">
          <a href="#" class="text-sm flex items-center space-x-1">
            <span>🇮🇩</span>
            <span>INDONESIA</span>
          </a>
          <a href="#" class="text-sm flex items-center space-x-1">
            <span>🇬🇧</span>
            <span>ENGLISH</span>
          </a>
        </div>
  
        <!-- Login/Signup -->
        <div class="flex items-center space-x-4">
          <a href="#" class="text-sm flex items-center hover:text-yellow-500"><i class="fas fa-sign-in-alt mr-1"></i> LOGIN</a>
          <a href="#" class="text-sm flex items-center hover:text-yellow-500"><i class="fas fa-user mr-1"></i> DAFTAR</a>
        </div>
      </div>
    </nav>
  
    <!-- Main Navbar with Logo, Social Media Icons, and Navigation Links -->
    <div class="bg-white py-4 shadow-md mb-10 lg:h-40 h-20">
      <div class="container mx-auto flex items-center justify-between relative">
        <!-- Hamburger Menu for Mobile -->
        <div class="md:hidden flex items-center">
          <button @click="toggleMenu" class="text-gray-600 focus:outline-none">
            <i class="fas fa-bars text-2xl"></i>
          </button>
        </div>
  
        <!-- Social Media Icons to the Left of Logo (Hidden on Mobile) -->
        <div class="hidden md:flex absolute left-0 space-x-3">
          <a href="#" class="text-gray-600 hover:text-black"><i class="fab fa-facebook-f"></i></a>
          <a href="#" class="text-gray-600 hover:text-black"><i class="fab fa-twitter"></i></a>
          <a href="#" class="text-gray-600 hover:text-black"><i class="fab fa-instagram"></i></a>
          <a href="#" class="text-gray-600 hover:text-black"><i class="fab fa-google"></i></a>
          <a href="#" class="text-gray-600 hover:text-black"><i class="fab fa-youtube"></i></a>
        </div>
  
        <!-- Logo in the Center -->
        <a href="#" class="mx-auto">
          <img src="../assets/logo.png" alt="Logo" class="w-38 h-24" />
        </a>
  
        <!-- Navigation Links (Centered Below the Logo) -->
        <ul class="hidden md:flex flex-row justify-center items-center absolute w-full bottom-[-30px] space-x-6 text-black font-semibold">
          <li><a href="#" class="hover:text-yellow-500">BERANDA</a></li>
          <li><a href="#" class="hover:text-yellow-500">TENTANG KAMI</a></li>
          <li><a href="#" class="hover:text-yellow-500">KONTAK MARKETING</a></li>
          <li><a href="#" class="hover:text-yellow-500">BERITA</a></li>
          <li><a href="#" class="hover:text-yellow-500">PAKET USAHA</a></li>
          <li><a href="#" class="hover:text-yellow-500">MEDIA SOSIAL</a></li>
          <li><a href="#" class="hover:text-yellow-500">KATEGORI</a></li>
        </ul>
  
        <!-- Search and Cart Icons to the Right of Logo -->
        <div class="hidden md:flex absolute right-0 items-center space-x-4">
          <a href="#" class="text-gray-600 hover:text-black"><i class="fas fa-search"></i></a>
          <a href="#" class="relative text-gray-600 hover:text-black">
            <i class="fas fa-shopping-cart"></i>
            <span class="absolute top-0 right-0 bg-yellow-500 text-xs text-white rounded-full px-1">0</span>
          </a>
        </div>
      </div>
    </div>
  
    <!-- Mobile Menu (Hidden on Desktop) -->
    <div :class="['fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity duration-300 md:hidden', { 'opacity-0 pointer-events-none': !isMenuOpen, 'opacity-100': isMenuOpen }]">
      <div :class="['fixed inset-y-0 left-0 w-64 bg-white shadow-lg transform transition-transform duration-300', { '-translate-x-full': !isMenuOpen, 'translate-x-0': isMenuOpen }]">
        <button @click="toggleMenu" class="text-gray-600 p-4 focus:outline-none absolute top-4 right-4">
          <i class="fas fa-times text-2xl"></i>
        </button>
        <ul class="p-4 space-y-4">
          <li><a href="#" class="block text-black hover:text-yellow-500">HOME</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">ABOUT US</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">MARKETING CONTACT</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">NEWS</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">BUSINESS PACKAGE</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">SOCIAL MEDIA</a></li>
          <li><a href="#" class="block text-black hover:text-yellow-500">CATEGORY</a></li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Navbar',
    data() {
      return {
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
    },
  };
  </script>
  
  <!-- Include Font Awesome for Icons -->
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  </style>
  