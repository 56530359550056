<template>
    <div class="promotion-grid bg-white py-10 px-6">
      <div class="container mx-auto max-w-screen-xl grid gap-6">
        <!-- Top Row: 2 Images -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div
            v-for="item in promotions.slice(0, 2)" 
            :key="item.title"
            class="relative group overflow-hidden rounded-lg shadow-md"
          >
            <!-- Background Image -->
            <img :src="item.image" :alt="item.title" class="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500" />
            
            <!-- Overlay Content -->
            <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center px-4 py-6">
              <h2 class="text-white text-3xl font-extrabold leading-tight">{{ item.title }}</h2>
              <p class="text-white mt-2 text-base font-light">{{ item.description }}</p>
            </div>
          </div>
        </div>
        
        <!-- Bottom Row: 3 Images -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div
            v-for="item in promotions.slice(2)" 
            :key="item.title"
            class="relative group overflow-hidden rounded-lg shadow-md"
          >
            <!-- Background Image -->
            <img :src="item.image" :alt="item.title" class="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500" />
            
            <!-- Overlay Content -->
            <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center px-4 py-6">
              <h2 class="text-white text-3xl font-extrabold leading-tight">{{ item.title }}</h2>
              <p class="text-white mt-2 text-base font-light">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PromotionGrid',
    data() {
      return {
        promotions: [
          {
            image: require('../assets/promotion/1.jpg'),
            title: 'Paket Terhemat 1,125K',
            description: 'Kamu sudah bisa mendapatkan 50 pcs aneka macam pakaian Anak',
          },
          {
            image: require('../assets/promotion/2.jpg'),
            title: 'Paket Terfavorit 2 JUTA',
            description: 'Kamu sudah bisa mendapatkan 100 pcs aneka macam pakaian Anak',
          },
          {
            image: require('../assets/promotion/3.jpg'),
            title: 'Paket Terpraktis 2,5 JUTA',
            description: 'Kamu sudah bisa mendapatkan aneka macam pakaian Anak & Dewasa yang bebas kamu pilih',
          },
          {
            image: require('../assets/promotion/4.jpg'),
            title: 'Paket Termurah 5 JUTA',
            description: 'Kamu sudah bisa mendapatkan aneka macam pakaian Anak & Dewasa yang bebas kamu pilih dengan harga Partai',
          },
          {
            image: require('../assets/promotion/5.jpg'),
            title: 'Paket Tercuan 20 JUTA',
            description: 'Kamu sudah bisa mendapatkan 1000 pcs aneka macam pakaian Anak',
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* Custom styles */
  .promotion-grid .container {
    max-width: 1900px;
  }
  
  .promotion-grid .group-hover\:scale-105 {
    transform: scale(1.05);
  }
  
  .promotion-grid .group:hover .group-hover\:scale-105 {
    transform: scale(1.05);
  }
  
  .promotion-grid img {
    transition: transform 0.5s ease;
  }
  </style>
  