<template>
    <div class="popular-products bg-white py-10 px-6">
      <div class="container mx-auto max-w-screen-xl">
        <!-- Header -->
        <h2 class="text-2xl font-bold mb-6">Most Popular Products</h2>
  
        <!-- Products Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
          <div
            v-for="(product, index) in products"
            :key="index"
            class="relative group overflow-hidden rounded-lg shadow-md"
          >
            <!-- Product Image -->
            <img :src="product.image" :alt="product.title" class="w-full h-64 object-cover" />
  
            <!-- Product Details -->
            <div class="text-center mt-4">
              <h3 class="text-lg font-semibold">{{ product.title }}</h3>
              <p class="text-red-500 font-semibold">{{ product.price }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PopularProducts',
    data() {
      return {
        products: [
          // Example product data (add more products as needed)
          { image: require('../assets/popular/1.jpg'), title: 'Jordan Kids Tshirt distributor', price: 'Rp. 23,500' },
          { image: require('../assets/popular/2.jpg'), title: 'Distributor of Cewe Child Stelan', price: 'Rp. 31,500' },
          { image: require('../assets/popular/3.jpg'), title: 'Cat Children\'s Stelan & Jack', price: 'Rp. 31,500' },
          { image: require('../assets/popular/4.jpg'), title: 'Baby Gap Leggings Distributor', price: 'Rp. 23,500' },
          { image: require('../assets/popular/5.jpg'), title: 'Distributor of Oshkosh Child Shortpants', price: 'Rp. 24,500' },
          { image: require('../assets/popular/6.jpg'), title: 'Distributor of Baby Gap Junior Tshirt', price: 'Rp. 28,500' },
          { image: require('../assets/popular/7.jpg'), title: 'Distributor of Uniqlo Junior Shirts', price: 'Rp. 39,500' },
          { image: require('../assets/popular/8.jpg'), title: 'Distributor of Dress Zara Junior', price: 'Rp. 44,500' },
          { image: require('../assets/popular/9.jpg'), title: 'Distributor of Old Navy Dres', price: 'Rp. 23,500' },
          { image: require('../assets/popular/10.jpg'), title: 'Old Navy Children\'s Dres', price: 'Rp. 23,500' },
          { image: require('../assets/popular/11.jpg'), title: 'Jordan Kids Tshirt distributor', price: 'Rp. 23,500' },
          { image: require('../assets/popular/12.jpg'), title: 'Distributor of Cewe Child Stelan', price: 'Rp. 31,500' },
          { image: require('../assets/popular/13.jpg'), title: 'Cat Children\'s Stelan & Jack', price: 'Rp. 31,500' },
          { image: require('../assets/popular/14.jpg'), title: 'Baby Gap Leggings Distributor', price: 'Rp. 23,500' },
          { image: require('../assets/popular/15.jpg'), title: 'Distributor of Oshkosh Child Shortpants', price: 'Rp. 24,500' },
          { image: require('../assets/popular/16.jpg'), title: 'Distributor of Baby Gap Junior Tshirt', price: 'Rp. 28,500' },
          { image: require('../assets/popular/17.jpg'), title: 'Distributor of Uniqlo Junior Shirts', price: 'Rp. 39,500' },
          { image: require('../assets/popular/18.jpg'), title: 'Distributor of Dress Zara Junior', price: 'Rp. 44,500' },
          { image: require('../assets/popular/19.jpg'), title: 'Distributor of Old Navy Dres', price: 'Rp. 23,500' },
          { image: require('../assets/popular/20.jpg'), title: 'Old Navy Children\'s Dres', price: 'Rp. 23,500' },
          // Add more products to ensure there are at least 50 products
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .popular-products .container {
    max-width: 1900px;
  }
  
  .popular-products img {
    transition: transform 0.5s ease;
  }
  
  .popular-products img:hover {
    transform: scale(1.05);
  }
  </style>
  