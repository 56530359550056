<template>
    <div class="latest-products bg-white py-10 px-6 relative">
      <div class="container mx-auto max-w-screen-xl">
        <!-- Header with Next/Back Buttons -->
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-2xl font-bold">Latest Products</h2>
          <div class="flex space-x-2">
            <button
              @click="prevProduct"
              class="bg-gray-200 text-gray-700 p-2 hover:bg-gray-300 focus:outline-none"
              aria-label="Previous"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button
              @click="nextProduct"
              class="bg-gray-200 text-gray-700 p-2 hover:bg-gray-300 focus:outline-none"
              aria-label="Next"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
    
        <!-- Products Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div
            v-for="(product, index) in currentProducts"
            :key="index"
            class="relative group overflow-hidden rounded-lg shadow-md transform transition duration-500 ease-in-out hover:shadow-lg"
          >
            <!-- Product Image -->
            <div class="relative overflow-hidden">
              <img :src="product.image" :alt="product.title" class="w-full h-64 object-cover transform transition duration-500 ease-in-out group-hover:scale-110" />
            </div>
    
            <!-- Product Details -->
            <div class="text-center mt-4">
              <h3 class="text-lg font-semibold">{{ product.title }}</h3>
              <p class="text-red-500 font-semibold">{{ product.price }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LatestProducts',
    data() {
      return {
        products: [
          {
            image: require('../assets/products/1.jpg'),
            title: 'Baby Gap Leggings Distributor',
            price: 'Rp. 23,500',
          },
          {
            image: require('../assets/products/2.jpg'),
            title: 'Distributor of Oshkosh Child Shortpants',
            price: 'Rp. 24,500',
          },
          {
            image: require('../assets/products/3.jpg'),
            title: 'Distributor of Baby Gap Junior Tshirt',
            price: 'Rp. 28,500',
          },
          {
            image: require('../assets/products/4.jpg'),
            title: 'Distributor of Uniqlo Junior Shirts',
            price: 'Rp. 39,500',
          },
          {
            image: require('../assets/products/5.jpg'),
            title: 'Distributor of Uniqlo Junior Shirts',
            price: 'Rp. 39,500',
          },
          {
            image: require('../assets/products/6.jpg'),
            title: 'Distributor of Uniqlo Junior Shirts',
            price: 'Rp. 39,500',
          },
  
          // Add more products as needed
        ],
        currentIndex: 0,
        itemsPerPage: 4,
      };
    },
    computed: {
      currentProducts() {
        return this.products.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
      },
    },
    methods: {
      nextProduct() {
        if (this.currentIndex + this.itemsPerPage < this.products.length) {
          this.currentIndex += this.itemsPerPage;
        }
      },
      prevProduct() {
        if (this.currentIndex > 0) {
          this.currentIndex -= this.itemsPerPage;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .latest-products button {
    transition: background-color 0.3s ease;
  }
  .latest-products .container {
    max-width: 1900px;
  }
  </style>
  
  <!-- Include Font Awesome for Icons -->
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  </style>
  