<template>
    <div class="article-slider bg-gray-100 py-10 px-6">
      <div class="container mx-auto max-w-screen-xl relative">
        <!-- Header -->
        <h2 class="text-2xl font-bold mb-6 text-center">Latest Article</h2>
  
        <!-- Article Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Article Item -->
          <div
            v-for="(article, index) in currentArticles"
            :key="`article-${index}`"
            class="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <!-- Article Image -->
            <div class="relative">
              <img :src="article.image" :alt="article.title" class="w-full h-80 object-cover" />
              <!-- Article Icon Overlay -->
              <div class="absolute top-4 left-4 bg-white p-1 rounded">
                <i class="fas fa-pen text-orange-600"></i>
              </div>
            </div>
  
            <!-- Article Content -->
            <div class="p-4">
              <h3 class="text-lg font-semibold mb-2">{{ article.title }}</h3>
              <p class="text-gray-600 mb-4">{{ article.description }}</p>
              <div class="flex items-center justify-between text-sm text-gray-500">
                <span>{{ article.date }}</span>
                <span><i class="fas fa-user"></i> {{ article.author }}</span>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Navigation Buttons -->
        <button
          @click="prevArticle"
          class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-700 p-2 rounded-full focus:outline-none hover:bg-gray-300"
          aria-label="Previous Article"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          @click="nextArticle"
          class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-700 p-2 rounded-full focus:outline-none hover:bg-gray-300"
          aria-label="Next Article"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ArticleSlider',
    data() {
      return {
        articles: [
          {
            image: require('@/assets/article/1.jpg'),
            title: 'LOEWE Paula’s Ibiza Collection Debut In Indonesia',
            description:
              'LOEWE Paula’s Ibiza Collection Debut In Indonesia IntroductionLOEWE, luxury fashion house from Spain, known for its expertise in creating fashion works that combine tradition with modern innovation [...]',
            date: 'June 22, 2024',
            author: 'Depok Branded Stocklots',
          },
          {
            image: require('@/assets/article/2.jpg'),
            title: 'Danger Wearing Newly Purchased Clothes',
            description:
              'Danger Wearing New Clothes Purchased Buying new clothes can be very fun, and the temptation to wear them right away certainly very big. However, wearing newly purchased clothes without [...]',
            date: 'June 20, 2024',
            author: 'Depok Branded Stocklots',
          },
          {
            image: require('@/assets/article/3.jpg'),
            title: 'Danger Wearing Newly Purchased Clothes',
            description:
              'Danger Wearing New Clothes Purchased Buying new clothes can be very fun, and the temptation to wear them right away certainly very big. However, wearing newly purchased clothes without [...]',
            date: 'June 20, 2024',
            author: 'Depok Branded Stocklots',
          },
          // Add more articles as needed
        ],
        currentIndex: 0,
        articlesPerPage: 2,
        slideInterval: null,
      };
    },
    computed: {
      currentArticles() {
        return this.articles.slice(this.currentIndex, this.currentIndex + this.articlesPerPage);
      },
    },
    methods: {
      nextArticle() {
        if (this.currentIndex + this.articlesPerPage < this.articles.length) {
          this.currentIndex += this.articlesPerPage;
        } else {
          this.currentIndex = 0; // Loop back to the first articles
        }
      },
      prevArticle() {
        if (this.currentIndex > 0) {
          this.currentIndex -= this.articlesPerPage;
        } else {
          this.currentIndex = Math.max(0, this.articles.length - this.articlesPerPage); // Go to the last articles
        }
      },
      startAutoSlide() {
        this.slideInterval = setInterval(this.nextArticle, 5000); // Auto-slide every 5 seconds
      },
      stopAutoSlide() {
        clearInterval(this.slideInterval);
      },
    },
    mounted() {
      this.startAutoSlide();
    },
    beforeUnmount() {
      this.stopAutoSlide();
    },
  };
  </script>
  
  <style scoped>
  .article-slider .container {
    max-width: 1200px;
  }
  
  .article-slider img {
    transition: transform 0.3s ease;
  }
  
  .article-slider img:hover {
    transform: scale(1.05);
  }
  
  .article-slider button {
    transition: background-color 0.3s ease;
  }
  </style>
  
  <!-- Include Font Awesome for Icons -->
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  </style>
  