<template>
    <div class="hero bg-white py-10 relative overflow-hidden">
      <div class="container mx-auto px-6 max-w-screen-xl">
        <!-- Carousel Container -->
        <div class="relative w-full" :style="{ height: `calc(45vw * 0.9)` }">
          <!-- Image Slides -->
          <div
            v-for="(image, index) in images"
            :key="index"
            :class="[
              'absolute w-full h-full transition-opacity duration-700 ease-in-out',
              currentIndex === index ? 'opacity-100' : 'opacity-0'
            ]"
            role="presentation"
          >
            <img :src="image.src" :alt="image.alt" class="w-full h-full object-cover" />
          </div>
  
          <!-- Navigation Buttons -->
          <button
            @click="prevSlide"
            class="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full focus:outline-none hover:bg-gray-700"
            aria-label="Previous Slide"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          <button
            @click="nextSlide"
            class="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full focus:outline-none hover:bg-gray-700"
            aria-label="Next Slide"
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Hero',
    data() {
      return {
        images: [
          { src: require('../assets/banner/1.jpg'), alt: 'Banner Image 1' },
          { src: require('../assets/banner/2.png'), alt: 'Banner Image 2' },
          { src: require('../assets/banner/3.png'), alt: 'Banner Image 3' },
        ],
        currentIndex: 0,
        slideInterval: null,
      };
    },
    mounted() {
      this.startAutoSlide();
    },
    methods: {
      startAutoSlide() {
        this.slideInterval = setInterval(this.nextSlide, 5000); // Change slide every 5 seconds
      },
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      },
      prevSlide() {
        this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
      },
    },
    beforeUnmount() {
      clearInterval(this.slideInterval);
    },
  };
  </script>
  
  <style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
  
  /* Custom CSS */
  .hero .container {
    max-width: 1900px;
  }
  
  .hero button {
    transition: background-color 0.3s ease;
  }
  </style>
  