<template>
  <div>
    <Navbar />
    <Hero />
    <PromotionGrid />
    <ProductGrid />
    <PopularProducts />
    <VideoGallery />
    <ArticleSlider />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Hero from './components/Hero.vue';
import PromotionGrid from './components/PromotionGrid.vue';
import ProductGrid from './components/ProductGrid.vue';
import PopularProducts from './components/PopularProducts.vue'; // Corrected: semicolon added
import VideoGallery from './components/VideoGallery.vue'; // Corrected: semicolon added
import ArticleSlider from './components/ArticleSlider.vue'; // Corrected: semicolon added
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Hero,
    PromotionGrid,
    ProductGrid,
    PopularProducts,
    VideoGallery,
    ArticleSlider,
    Footer,
  },
};
</script>
